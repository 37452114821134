import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import Page from './routes/page/Page';
import FAQ from './routes/FAQ/FAQ';
import Contact from './routes/Contact/Contact';
import { useEffect } from 'react';
import { theme } from './style/theme';
import { useSetAtom } from 'jotai';
import { mobileAtom } from './atoms/global';

function App() {

	const setMobile = useSetAtom(mobileAtom);

	useEffect(() => { 
		window.matchMedia(`(max-width: ${theme.responsive.media.lg})`).addEventListener('change', (e) => setMobile(e.matches));
		return (() => {
			window.matchMedia(`(max-width: ${theme.responsive.media.lg})`).removeEventListener('change', (e) => setMobile(e.matches));
		});
	}, []);

	return (
		<BrowserRouter>
			<Layout>
				<Routes>
					<Route path={'/'} element={ <Page/>}/>
					<Route path={'/:page'} element={ <Page/>}/>
					<Route path={'/:page/:detailedpage'} element={ <Page/>}/>
					<Route path={'/FAQ'} element={ <FAQ/>}/>
					<Route path={'/contact'} element={ <Contact/>}/>
				</Routes>
			</Layout>
		</BrowserRouter>
	);
}

export default App;
