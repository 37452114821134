import styled from 'styled-components';
import { Feature, IconVisual as IconVisualType, ImageVisual as ImageVisualType } from '../../../data/pageItems';
import BasicButton from '../../../components/buttons/BasicButton';
import ImageVisual from '../components/ImageVisual';
import IconVisual from '../components/IconVisual';
import { useAtomValue } from 'jotai';
import { mobileAtom } from '../../../atoms/global';
import TextBlock from '../components/TextBlock';

const FeatureItem = ({data} : {data: Feature}) => {
	const mobile = useAtomValue(mobileAtom);

	return ( 
		<Container style={{flexDirection: data.visuals.length > 1 || mobile ? 'column' : 'row'}}>
			
			{/* {data.positioning === 'top/left' && <Visuals visuals={data.visuals}/>} */}

			<TextContainer>
				<TextBlock data={data.text}/>
				{data.buttonText && <BasicButton $fill>
					{data.buttonText}
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.16663 4.5L12.8333 8L9.16663 11.5M12.6666 8H3.16663" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</BasicButton>}
			</TextContainer>

			{data.visuals.length > 1 ? <Visuals visuals={data.visuals}/> : 
				<>{data.visuals[0].type === 'image' ?
					<ImageVisual data={data.visuals[0]} style={{width: mobile ? '100%' : '50%', marginTop: '40px'}}/>
					:
					<IconVisual data={data.visuals[0]} />
				}</>}
		</Container>
	);
};

const Visuals = ({visuals} : {visuals: (IconVisualType | ImageVisualType)[]}) => {
	const mobile = useAtomValue(mobileAtom);

	return (
		<VisualsContainer >
			{visuals.map((visual) => {
				if(visual.type === 'image')
					return (<ImageVisual data={visual} style={{width: mobile ? '100%' : '50%', marginTop: '40px'}} /> );
				else 
					return (<IconVisual data={visual} style={{width: mobile ? '100%' : '50%', marginTop: '16px'}} /> );
			})}
		</VisualsContainer>
	);
};

const Container = styled.div`
	position: relative;

	width: 100%;

	display: flex;
	justify-content: space-between;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		flex-direction: column;
    }; 
`;


const TextContainer = styled.div`

	width: 35%;
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		width: 100%;
    }; 

`;

const VisualsContainer = styled.div`
	display: flex;
	gap: 5%;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		flex-direction: column;
    }; 
`;
 
export default FeatureItem;