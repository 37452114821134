import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import StyleProvider from './style/StyleProvider.tsx';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './lang/en.ts';
import nl from './lang/nl';

i18n
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: en
			},
			nl: nl
		},
		lng: 'en', // if you're using a language detector, do not define the lng option
		fallbackLng: 'en',

		interpolation: {
			escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		}
	});

createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<StyleProvider>
			<App />
		</StyleProvider>
	</StrictMode>,
);
