import styled, { css } from 'styled-components';
import { ImageVisual, VisualData } from '../../../data/pageItems';
import { BasicLink } from '../../../components/buttons/BasicLink';
import { useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown';

const Visual = ({data, style, hoverable} : {data: VisualData & ImageVisual, style?: React.CSSProperties, hoverable?: boolean}) => {

	const navigate = useNavigate();

	const move = () => {
		if(!data.link || ! hoverable) return;
		navigate(data.link);
	};

	return ( 
		<Container onClick={move} $hover={hoverable} style={style} src={data.img}>

			{data.link ? 			
				<LinkContainer> 
					<label>{data.text.label}</label>
			
					<BasicLink to={data.link}>
						{data.text.title}   
						<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.16663 5.95497L9.49996 8.12164L7.16663 10.2883" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</BasicLink>
				</LinkContainer>
				:
				<TextContainer> 
					<h1>{data.text.title}</h1>
					<Markdown>{data.text.description}</Markdown>
				</TextContainer>
			}				
		</Container>
	);
};

const Container = styled.div<{$hover: boolean | undefined, src: string}>`
	aspect-ratio: 1/0.77;
	
	border-radius: 25px;

	background: url(${p => p.src}) no-repeat;
	background-size: 100% 100%;

    padding: 2%;

	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		border-radius: 10px;
		padding: 5%;
    }; 

	${p => p.$hover && css`
		&:hover {
			cursor: pointer;

			scale: 1.1;	

			transition: scale 0.25s;	
			svg{
				transform: translateX(35%);
				transition: transform 0.25s;
			}

			a {
				text-decoration: none;
			}
		}

	`} 

`;

const LinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	gap: 10px;

	a {
		&:hover {
			svg{
				transform: translateX(35%);
				transition: transform 0.25s;
			}
		}
	}

`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;

	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		font-size: 16px;
		h1 {
			font-size: 20px;
		}
    }; 
`;


export default Visual;