import pageItems, { PageItem } from './pageItems';

const content: Content = {

	pages: {
		'home':{
			title: 'Home',
			items: [
				pageItems['home-hero'],
				pageItems['home-feature'],
				pageItems['home-logocarousel'],
				pageItems['home-cta'],
			],
			subPages: {}
		},		
		'video':{
			title: 'Video',
			items: [],
			subPages: {
				'usfgo' : {
					title: 'USF.Go',
					items: [
						pageItems['usfgo-hero'],
						pageItems['usfgo-feature1'],
						pageItems['usfgo-feature2'],
						pageItems['usfgo-feature3'],

					],

				},
				'usfpro' :{
					title: 'USF.Pro',
					items: [],
					
				},
				'usfelite' :{
					title: 'USF.Elite',
					items: [],
										
				},
				'clubtv' : {
					title: 'ClubTV',
					items: [],
															
				}
			}
		},
		'performance':{
			title: 'Performance',
			items: [],
			subPages: {
				'imocloud': {
					title: 'ImoCloud' ,
					items: []
				},
				'imoclient':{
					title: 'GPS',
					items: []
				},
				'gps':{
					title: 'GPS',
					items: []
				},
				'lpm':{
					title: 'LPM',
					items: []
				}
			}
		},

		'about':{
			title: 'about.title',
			items: [
				// pageItems['aboutus-image-1']
			],
			subPages: {
				'story': {
					title: 'Our story',
					items: []
				},
				'team': {
					title: 'Team',
					items: []
				},
				'jobs':{
					title: 'Jobs',
					items: []
				}
			}
		},
		'contact':{
			title: 'Contact',
			items: [],
			subPages: {
				'docs': {
					title: 'Docs',
					items: []
				}
			}
		},
	}

};


type Content = {
    pages: {[page: string] : Page}
}

export type Page = {
	//status: 'draft' | 'published' | 'archived' , // only shows when published
	title: string,
	items: PageItem[],
	subPages: {[subPage: string] : SubPage}
}

export type SubPage = {
	title: string,
	items: PageItem[],
}


export default content;
