import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import content from '../../data/content';
import styled from 'styled-components';
import HeroItem from './items/HeroItem';
import FeatureItem from './items/FeatureItem';
import CarouselItem from './items/CarouselItem';
import CTAItem from './items/CTAItem';

const Page = () => {
	const params = useParams();

	const pageData = useMemo(() => {
		if(!params.page)
			return  content.pages['home'];
		else if(params.detailedpage)	
			return content.pages[params.page].subPages[params.detailedpage];
		else 
			return content.pages[params.page];

	}, [params]);

	const ItemComponents : {[key: string]: any} =  {
		'hero' : HeroItem,
		'feature' : FeatureItem,
		'carousel' : CarouselItem,
		'cta' : CTAItem,
	};

	return (
		<Container>
			{ 
				pageData ? pageData.items.map((item, index) => {
					const Component = ItemComponents[item.type];
					if(Component)
						return (
							<Component key={`${item.type}-${index}`} data={item}/>
						);
				})
					:
					<div>
						Page not found
					</div>
			}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;


	&  > * {
		padding: 5%;
	}

`;

export default Page;