const icons = {
	cameraStand: (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.91675 11.2501C7.91675 9.40913 9.40913 7.91675 11.2501 7.91675H28.7501C30.5911 7.91675 32.0834 9.40913 32.0834 11.2501V22.0834C32.0834 23.9244 30.5911 25.4167 28.7501 25.4167H11.2501C9.40913 25.4167 7.91675 23.9244 7.91675 22.0834V11.2501Z" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M23.75 16.6667C23.75 18.7377 22.071 20.4167 20 20.4167C17.929 20.4167 16.25 18.7377 16.25 16.6667C16.25 14.5957 17.929 12.9167 20 12.9167C22.071 12.9167 23.75 14.5957 23.75 16.6667Z" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M20 25.8333V32.0833" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M20.0001 25.8333L12.9167 32.0833" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M20 25.8333L27.0833 32.0833" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>

	),
	storage: (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.91675 9.58341C7.91675 8.66295 8.66295 7.91675 9.58341 7.91675H30.4167C31.3372 7.91675 32.0834 8.66295 32.0834 9.58341V15.4167C32.0834 16.3372 31.3372 17.0834 30.4167 17.0834H9.58341C8.66293 17.0834 7.91675 16.3372 7.91675 15.4167V9.58341Z" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M7.91675 24.5834C7.91675 23.6629 8.66295 22.9167 9.58341 22.9167H30.4167C31.3372 22.9167 32.0834 23.6629 32.0834 24.5834V30.4167C32.0834 31.3372 31.3372 32.0834 30.4167 32.0834H9.58341C8.66293 32.0834 7.91675 31.3372 7.91675 30.4167V24.5834Z" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M27.0833 8.33325V16.6666" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M27.0833 23.3333V31.6666" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	),
	wallet: (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M21.2501 32.0834H28.7501C30.5911 32.0834 32.0834 30.5911 32.0834 28.7501V16.2501C32.0834 14.4091 30.5911 12.9167 28.7501 12.9167H11.2501C9.40913 12.9167 7.91675 14.4091 7.91675 16.2501V17.0834" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M28.75 12.0834V11.2501C28.75 9.40913 27.2577 7.91675 25.4167 7.91675H14.5833C12.7424 7.91675 11.25 9.40913 11.25 11.2501V12.0834" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M15.4167 20.8333H13.3334C10.3419 20.8333 7.91675 23.2584 7.91675 26.2499C7.91675 29.2414 10.3419 31.6666 13.3334 31.6666C14.4127 31.6666 15.4055 31.3773 16.2501 30.8333" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.3333 24.5834L16.6666 21.2501L13.3333 17.9167" stroke="#38AA34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>

	)
};

export default icons;