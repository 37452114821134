import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const BasicLink = styled(NavLink)`
	text-decoration: none;
	display: flex;
	align-items: center;

	&:hover, :focus {
		text-decoration: underline;
	}

	&.active{
   		color: ${p => p.theme.colors.secondary}
	};
`;