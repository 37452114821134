
// Light Theme

export const theme = {
	colors: {
		primary: '#0A0618',
		primaryLight: '#3D5795',
		secondary: '#77B929',
		secondaryDark: '#355212',

		neutralLightest: '#FFFFFF',
		neutralDarkest: '#000000',
		neutralDarker: '#121318'
	},
	styles: { // style

	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1000px',
			xl: '1440px',
		},
		whitespace : {
			xs: 25,
			sm: 25,
			lg: 50,
			xl: 100,
		}
	}
};