import styled, { css } from 'styled-components';
import BaseButton from './BaseButton';

const BasicButton = styled(BaseButton)<{$fill?: boolean}>`

    background-color: ${p => p.theme.colors.neutralDarker};
    color: ${p => p.theme.colors.neutralLightest};
    border: 2px solid ${p => p.theme.colors.secondary};
	border-radius: 50em;

    gap: 10px;
    display: flex;
	align-items: center;

    height: max-content;

    ${ p => p.$fill && css`
        background-color: ${p => p.theme.colors.secondary};

	`}

    &:hover {
        background: rgba(118, 184, 40, 0.33);
    }
`;


 
export default BasicButton;