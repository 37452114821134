import { ReactNode } from 'react';
import styled from 'styled-components';
import Navigation from './Header/Header';
import Footer from './Footer/Footer';

const Layout = (props: {children: ReactNode}) => {
	
	return ( 
		<Container>
			
			<NavigationContainer>
				<TopBorder/>
				<Navigation/>
			</NavigationContainer>

			<ScrollingContentWindow id={'scrollingContentWindow'}>
				<div style={{flexGrow: 1}}>{ props.children }</div>
				<Footer/>
			</ScrollingContentWindow>

		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

`;

const TopBorder = styled.div`
	background-image: linear-gradient(to right, ${p => p.theme.colors.secondary} , ${p => p.theme.colors.primaryLight});
	height: 2px;
	width: 100%;
`;

const NavigationContainer = styled.div`
	height:0;
	position: sticky;
	left:0;top:0;right:0;
	z-index: 5;
`;

const ScrollingContentWindow = styled.div`
	height: 100vh;
	overflow: auto;

	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll; 
	::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}
`;

 
export default Layout;