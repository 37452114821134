import { createGlobalStyle } from 'styled-components';
import { fonts } from './fonts/fonts';

export const GlobalStyle = createGlobalStyle`

	${fonts}

	html, body, #root {
		height: 100%;
		margin: 0;
		padding:0;
		font-family: 'Barlow', sans-serif;
		overflow: hidden;
		font-size: 18px;
		color: ${p => p.theme.colors.neutralLightest};
		background-color: ${p => p.theme.colors.primary};
	}

	* {
		box-sizing: border-box;
		user-select: none;
		-webkit-user-select: none;
	}

	a {
        color: inherit;
        text-decoration: none;
    }

	/* p, h1, h2, h3 {
		margin: 0;
	} */

	h1 {
		font-size: 32px;
		margin: 0;
	}

	label {
		color: ${p => p.theme.colors.secondary};
		font-size: 14px;
	}
`;