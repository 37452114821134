import styled from 'styled-components';
import { CTA } from '../../../data/pageItems';
import TextBlock from '../components/TextBlock';
import BasicButton from '../../../components/buttons/BasicButton';

const CTAItem = ({data} : {data: CTA}) => {
	const actions: {[key: string] : {text: string}}  = {
		'demo' : {
			text: 'Request a demo'
		}
	};

	return ( 
		<Container>
			<Box>
				<TextBlock data={data.text}/>
				<BasicButton $fill>
					{actions[data.action].text}
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.16663 4.5L12.8333 8L9.16663 11.5M12.6666 8H3.16663" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</BasicButton>
			</Box>
		</Container>
	);
};

const Container = styled.div`
    position: relative;

    width: 100%;

`;

const Box = styled.div`
    padding: 5%;
    background: radial-gradient(158.58% 158.58% at 50% 0%, ${p => p.theme.colors.secondary} 0%, ${p => p.theme.colors.secondaryDark} 100%);
    border-radius: 24px;

`;

export default CTAItem;