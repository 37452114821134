import { useTranslation } from 'react-i18next';
import { BasicLink } from '../../components/buttons/BasicLink';
import content from '../../data/content';
import styled from 'styled-components';
import BasicButton from '../../components/buttons/BasicButton';
import { useAtomValue } from 'jotai';
import { mobileAtom } from '../../atoms/global';
import logo from '../../assets/images/logo.png';
import { useEffect, useState } from 'react';
import { theme } from '../../style/theme';
import SlidingPanel from './SlidingPanel';

const Navigation = () => {
	const {t} = useTranslation();
	const mobile = useAtomValue(mobileAtom);
	const [openPanel, setOpenPanel] = useState(false);

	const [activeDropdown, ] = useState<string | undefined>();

	// const toggleDropdown = (label: string | undefined) => {
	// 	setActiveDropdown(label);
	// };

	useEffect(() => {
		const swc = document.getElementById('scrollingContentWindow');
		if(swc === null) return;

		swc.addEventListener('scroll', scrollListener, { passive: true });

		return () => {
			swc.removeEventListener('scroll',scrollListener);
		};
	},[]);

	const [scrollPosition, setScrollPosition] = useState<boolean>(true);
	const scrollListener = (e: any) => {
		const position = e.srcElement.scrollTop;
		setScrollPosition(position === 0 ? true : false);
	};

	return ( 				
		<Container $gradient={activeDropdown ? false : scrollPosition}>

			<Logo src={logo}/>

			{mobile ? 
				<>
					{!openPanel ? 
						<svg onClick={() => setOpenPanel(true)} style={{marginRight: '10px'}} width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.75 5.75H19.25M4.75 18.25H19.25M4.75 12H19.25" stroke={theme.colors.secondary} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						</svg> 
						:
						<svg onClick={() => setOpenPanel(false)} style={{marginRight: '10px'}}width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.25 6.75L6.75 17.25" stroke={theme.colors.secondary} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M6.75 6.75L17.25 17.25" stroke={theme.colors.secondary} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					}
					<SlidingPanel open={openPanel} setOpen={setOpenPanel}/>
				</>
				: 
				<>
					<Pages>
						{Object.entries(content.pages).map(([pageId, page]) => {
							return (
								<BasicLink key={`page-${pageId}`} to={pageId === 'home' ? '/' : `/${pageId}`}>{t(page.title)}</BasicLink>
							);
						})}
					</Pages>
					<BasicButton onClick={() => window.open('https://dash.usf.sport/','_blank')}>
							Login 
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12.8334 7.99999C12.8334 10.6694 10.6694 12.8333 8.00002 12.8333C5.33064 12.8333 3.16669 10.6694 3.16669 7.99999C3.16669 5.33061 5.33064 3.16666 8.00002 3.16666C10.6694 3.16666 12.8334 5.33061 12.8334 7.99999Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M9.5 6.66666C9.5 7.49508 8.82843 8.16666 8 8.16666C7.17157 8.16666 6.5 7.49508 6.5 6.66666C6.5 5.83823 7.17157 5.16666 8 5.16666C8.82843 5.16666 9.5 5.83823 9.5 6.66666Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M4.79828 11.3333C5.56259 10.4167 6.71316 9.83334 7.99998 9.83334C9.28679 9.83334 10.4374 10.4167 11.2017 11.3333" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</BasicButton>
				</>
			}
		</Container>

	);
};

const Container = styled.div<{$gradient: boolean}>`
	height: 8vh;
	position: relative;

	display: flex;
	align-items: center ;
	justify-content: space-between;
	padding: 1% 3%;
	background-image: linear-gradient(${p => p.theme.colors.primary},rgba(255,0,0,0));

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: ${p => p.$gradient ? 'none' : p.theme.colors.primary};
		transition: background-color .25s;
		z-index: -1;
	}

`;

const Logo = styled.img`
	height: 32px;
`;

const Pages = styled.div`
    display: flex;
	gap: 24px;
`;
 
export default Navigation;

