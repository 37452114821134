import styled, { css } from 'styled-components';
import { Hero } from '../../../data/pageItems';
import BasicButton from '../../../components/buttons/BasicButton';
import Tile from '../components/ImageVisual';
import { useMemo } from 'react';

const HeroItem = ({data}: {data: Hero}) => {

	const hasTiles = useMemo(() =>{
		if (data.leftTile || data.rightTile) return true;
		return false;
	}, [data]);

	return ( 
		<>
			<Container src={data.backgroundImg} $tiles={hasTiles}>
				{hasTiles ? 
					<>
						<div/>
						<TextContainer>
							<label>{data.text.label}</label>
							<h1>{data.text.title}</h1>
							<BasicButton $fill={true}>Book a call</BasicButton>
						</TextContainer>
						<TilesContainer>
							{data.leftTile && <Tile hoverable data={data.leftTile} style={{width: '45%'}}/>}
							{data.rightTile && <Tile hoverable data={data.rightTile} style={{width: '45%'}}/>}
						</TilesContainer>
					</> :
					<>
						<label>{data.text.label}</label>
						<h1>{data.text.title}</h1>
					</>
				}


			</Container>
			{data.text.description && <p style={{margin: 0}}>{data.text.description}</p>}
		</>
	);
};

const Container = styled.div<{src: string, $tiles: boolean}>`
	position: relative;

	display: flex;
	flex-direction: column;
	gap: 140px;

	${p => !p.$tiles && css`
		height: 550px;
		gap: 24px;
		justify-content: flex-end;
	    background: url(${p.src}) no-repeat!important;
    	background-size: cover!important;
		
		& > label {
			font-size: 16px;
			font-weight: bold;
		}
	`}

    background: linear-gradient(to top, ${p => p.theme.colors.primary} 32%, rgba(0,0,0,0) 32%), url(${p => p.src}) no-repeat;
    background-position: right top;
    background-size: cover;

	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
	    background: linear-gradient(to top, ${p => p.theme.colors.primary} 10%, rgba(0,0,0,0) 10%), url(${p => p.src}) no-repeat;
		background-position: 75% top;
	}; 

`;


const TextContainer = styled.div`
	max-width: 400px;
	text-align: center;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;

	align-self: center;
	margin-top: auto;

	& > label {
		font-size: 16px;
		font-weight: bold;
	}

`;

const TilesContainer = styled.div`

	display: flex;
	justify-content: space-around;
	align-self: center; 

	margin-top: auto;

	width: 100%;
	max-width: 800px;
	
`;



 
export default HeroItem;