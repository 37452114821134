// import icons from '../assets/icons'
import backgroundHero from '../assets/images/backgroundHero.png';
import usfgo from '../assets/images/usfgo.png';
import videoTile from '../assets/images/goTile.png';
import performanceTile from '../assets/images/playersTile.png';
import goCamera from '../assets/images/gocamera.png';
import ajax from '../assets/images/logos/Ajax.png';
import psv from '../assets/images/logos/PSV.png';
import knvb from '../assets/images/logos/knvb.png';
import harvard from '../assets/images/logos/Harvard.png';
import RBSalzburg from '../assets/images/logos/RBSalzburg.png';
import vitesse from '../assets/images/logos/Vitesse.png';
import Everton from '../assets/images/logos/Everton.png';
import go1 from '../assets/images/go1.png';
import go2 from '../assets/images/go2.png';
import go3 from '../assets/images/go3.png';
import icons from '../assets/icons';


const pageItems: {[key: string]: PageItem } = {
	'home-hero':{
		type: 'hero',
		backgroundImg: backgroundHero,
		text: {
			label: 'STREAM. ANALYZE. IMPROVE',
			title: 'Elevate play with data and video',
		},
		leftTile: {
			type: 'image',
			img: videoTile,
			text: {
				title: 'Video',
			},
			link: '/video'
		},
		rightTile: {
			type: 'image',
			img: performanceTile,
			text: {
				title: 'Performance',
			},
			link: '/performance'
		}

	},
	'home-feature':{
		type: 'feature',
		positioning: 'bottom/right',
		text: {
			label: 'FOR WINNING TEAMS',
			title: 'Capture Every Detail, Improve Every Play',
			description: '**"Stream, analyze, improve"** perfectly sums up how USF.Sport empowers teams to perform at their best. With our cutting-edge camera systems, every game is streamed live or recorded for future analysis. Paired with advanced data tools like GPS and LPM, you gain the insights needed to boost individual and team performance. \n\nWhether you\'re enhancing tactical play or evaluating player development, USF.Sport helps you elevate play with data and video.',
		},
		buttonText: 'Discover more',
		visuals: [{
			type: 'image',
			img: goCamera,
			text: {
				label: 'NEW',
				title: 'USF.Go',
			},
			link: 'video/usfgo'
		}]
	},
	'home-logocarousel':{
		type: 'carousel',
		text: {
			label: 'TRUSTED BY',
			title: 'Our clients and partners',
		},
		slides: [
			psv, knvb, ajax, harvard, vitesse, Everton, RBSalzburg
		]
	},
	'home-cta':{
		type: 'cta',
		text: {
			title: 'Let’s talk video and performance',
			description: 'Chat with us and see why clubs and team looking for a modern video and data solution \n\n choose USF'
		},
		action: 'demo'
	},
	'usfgo-hero':{
		type: 'hero',
		backgroundImg: usfgo,
		text: {
			label: 'USF Go Camera',
			title: 'Capture every moment, anywhere.',
			description: 'Bring professional-quality game footage to your team – anytime, anywhere. The USF Go mobile camera makes filming effortless so you can capture every play, analyze performances, and enhance fan engagement from anywhere, even away games. With USF Go, you stay focused on what matters: the game.'
		}
	},
	'usfgo-feature1':{
		type: 'feature',
		positioning: 'bottom/right',
		text: {
			label: 'ANYTIME, ANYWHERE',
			title: 'Quick setup, maximum flexibility',
			description: 'The USF Go is designed for ultimate convenience. Lightweight with an adjustable mast (up to 7 meters) and a compact carry case, it\'s built to travel with you. Set up on any field, indoors or outdoors, and start recording in minutes.',
		},
		visuals: [
			{
				type: 'image',
				img: go1,
				text: {
					title: 'Easy to transport',
					description: 'Ideal for away games or multi-field setups',
				},
			},
			{
				type: 'image',
				img: go2,
				text: {
					title: 'Adjustable height',
					description: 'Perfect angle and coverage, up to 7 meters',
				}
			}
		]
	},
	'usfgo-feature2':{
		type: 'feature',
		positioning: 'bottom/right',
		text: {
			label: 'EVERYTHING YOU NEED',
			title: 'Hands-free AI camera for uninterrupted game coverage',
			description: 'Imagine a camera that follows the game for you. The AI-powered midline camera automatically tracks play across the entire field, so no moment goes uncaptured. Boost livestream quality, simplify analysis, and eliminate the need for a dedicated cameraman.',
		},
		visuals: [
			{
				type: 'image',
				img: go1,
				text: {
					title: 'Auto-tracking',
					description: 'Full-field coverage, hands-free'
				},
			},
			{
				type: 'image',
				img: go2,
				text: {
					title: 'High-quality livestreams',
					description: 'Engage fans and supporters in real-time'
				},
			},
			{
				type: 'image',
				img: go3,
				text: {
					title: 'AI-driven precision',
					description: 'Accurate footage without manual adjustments'
				},
			}
		]
	},
	'usfgo-feature3':{
		type: 'feature',
		positioning: 'bottom/right',
		text: {
			label: 'TAILORED TO YOUR NEEDS',
			title: 'Flexible Licensing',
			description: 'Only pay for what you need with USF Sport’s storage plans. Whether it’s 120, 240, or 480 hours of storage, you control your costs. One camera serves your entire club, so multiple teams and sports are always covered – no extra costs or limits.',
		},
		visuals: [
			{
				type: 'icon',
				icon: 'cameraStand',
				text: {
					title: 'One camera for all teams',
					description: 'One-time setup, multi-team coverage'
				},
			},
			{
				type: 'icon',
				icon: 'storage',
				text: {
					title: 'Custom storage options',
					description: '120, 240, or 480 hours'
				},
			},
			{
				type: 'icon',
				icon: 'wallet',
				text: {
					title: 'Transparent pricing',
					description: 'No surprise fees, just what you need'
				},
			}
		]
	},
};

export type PageItem =  Hero | Feature | Carousel | CTA

export type Hero = {
	type: 'hero'
	backgroundImg: string,
	text: TextBlockData,
	leftTile?: ImageVisual,
	rightTile?: ImageVisual,
}

export type Carousel = {
	type: 'carousel',
	text: TextBlockData
	slides: string[]
}

export type Feature = {
	type: 'feature',
	positioning: 'top/left' | 'bottom/right',
	buttonText?: string,
	text: TextBlockData
	visuals: (ImageVisual | IconVisual)[]
}

export type CTA = {
	type: 'cta',
	text: TextBlockData,
	action: 'demo' | ''
}

export type VisualData = {
	text: TextBlockData
}

export type IconVisual ={
	type: 'icon'
	icon: keyof typeof icons
} & VisualData

export type ImageVisual = {
	type: 'image'
	img: string,
	link?: string
} & VisualData

export type TextBlockData = {
	label?: string,
	title: string,
	description?: string,
}


export default pageItems;