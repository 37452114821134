import Markdown from 'react-markdown';
import styled from 'styled-components';
import { TextBlockData } from '../../../data/pageItems';

const TextBlock = ({data} : {data: TextBlockData}) => {
	return ( 
		<Container>
			<label>{data.label}</label>
			<h1 style={{marginTop: data.label ? '8px' : 0}}>{data.title}</h1>
			<Markdown>{data.description}</Markdown>
		</Container>
	);
};

const Container = styled.div`


`;
 
export default TextBlock;