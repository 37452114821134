import styled from 'styled-components';
import { IconVisual } from '../../../data/pageItems';
import Markdown from 'react-markdown';
import icons from '../../../assets/icons';


const Visual = ({data, style} : {data: IconVisual, style?: React.CSSProperties}) => {


	return ( 
		<Container style={style}>
			{icons[data.icon]}

			<TextContainer> 
				<h1>{data.text.title}</h1>
				<Markdown>{data.text.description}</Markdown>
			</TextContainer>				
		</Container>
	);
};

const Container = styled.div`
	/* aspect-ratio: 1/0.77; */
	
	border-radius: 25px;

    padding: 2%;

	display: flex;
	gap: 5%;

	background: linear-gradient(169deg, rgba(23, 15, 59, 0.80) 17.92%, rgba(10, 6, 24, 0.00) 75.75%);


	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		border-radius: 10px;
		padding: 5%;
    }; 
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;

	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		font-size: 16px;
		h1 {
			font-size: 20px;
		}
    }; 
`;


export default Visual;