import styled from 'styled-components';

const BaseButton = styled.button`
	border:none;
	outline:none;
	background: none;
	margin: 0;
    padding: 12px;

	&:hover, :focus {
		text-decoration: underline;
	}

	cursor: pointer;

	font-family: 'Barlow Condensed', sans-serif;
	font-weight: bold;

`;

export default BaseButton;