import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from 'styled-components';
import { Carousel } from '../../../data/pageItems';
import TextBlock from '../components/TextBlock';


const CarouselItem = ({data} : {data: Carousel}) => {
  
	return (
		<Container>
			<TextBlock data={data.text}/>
			<Swiper
				id='swiper-container'
				style={{margin: '0% -5%'}}
				slidesPerView={4}
				loop
			>

				{data.slides.map((slide) => {
					return (
						<SwiperSlide key={`slide-${slide}`}><Logo  src={slide}/></SwiperSlide>
					);
				})}

			</Swiper>
		</Container>
	);
};

const Container = styled.div`
  position: relative;
	width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  //mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		margin: 100px 0;
  }; 
  
  #swiper-container {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 5%; 
      z-index: 10; 
      pointer-events: none; 
    }

    &::before {
      left: 0;
      background: linear-gradient(270deg, rgba(10, 6, 24, 0.00) 0%, ${p => p.theme.colors.primary} 100%);
    }

    &::after {
      right: 0;
      background: linear-gradient(270deg, ${p => p.theme.colors.primary} 0%, rgba(10, 6, 24, 0.00) 100%);
      }
  }

  
`;

const Logo = styled.img`
  height: 60px;
  aspect-ratio: 1/1;
  object-fit: contain;

`;

 
export default CarouselItem;