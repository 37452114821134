import styled, { css } from 'styled-components';
import content from '../../data/content';
import { theme } from '../../style/theme';
import { BasicLink } from '../../components/buttons/BasicLink';
import { useTranslation } from 'react-i18next';
import BasicButton from '../../components/buttons/BasicButton';
import bg from '../../assets/images/slidingspanelbg.png';

const SlidingPanel = ({open, setOpen} : {open: boolean, setOpen: (val: boolean) => void}) => {
	const {t} = useTranslation();

	return ( 
		<Container img={bg} open={open} id='slidingpanel'>
			<Content>
				<Pages>
					{Object.entries(content.pages).map(([pageId, page]) => {
						return (
							<>
								<BasicLink onClick={() => setOpen(false)} to={pageId}><p style={{color: theme.colors.secondary, marginBottom: 0}}>{page.title}</p></BasicLink>
								{Object.entries(page.subPages).map(([key, subPage]) =>{
									return (				
										<BasicLink onClick={() => setOpen(false)} key={`page-${pageId}-${key}`} to={pageId === 'home' ? '/' : `/${pageId}/${key}`}>
											{t(subPage.title)}
										</BasicLink>
									);
								})}
							</>
						);
					})}
				</Pages>
				<Buttons>
					{/* <BasicButton>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.5 12.8333L10.6667 8.5L12.8333 12.8333" stroke="#D1D1DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M9.33325 11.5H11.9999" stroke="#D1D1DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M3.16675 4.5H8.83341" stroke="#D1D1DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M6 4.33332V3.16666" stroke="#D1D1DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M7.50008 4.5C7.50008 4.5 7.50008 6.16667 6.16675 7.5C4.83341 8.83333 3.16675 8.83333 3.16675 8.83333" stroke="#D1D1DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M8.16667 8.83333C8.16667 8.83333 6.5 8.83333 5.16667 7.5C4.89701 7.23034 4.5 6.5 4.5 6.5" stroke="#D1D1DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
                    NL
					</BasicButton> */}
					<BasicButton onClick={() => window.open('https://dash.usf.sport/','_blank')}>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.50008 5.83332L8.83341 7.99999L6.50008 10.1667M6.50008 3.16666H11.5001C12.2365 3.16666 12.8334 3.76361 12.8334 4.49999V11.5C12.8334 12.2364 12.2365 12.8333 11.5001 12.8333H6.50008M8.66675 7.99999H3.16675" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
                    Login
					</BasicButton>
				</Buttons>
			</Content>
		</Container>
	);
};

const Container = styled.div<{open: boolean, img: string}>`
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	z-index: -1;

	left:0;top:0;
	width: 100%;
	height: 100vh;
	padding-top: 10vh;

	background: url(${p => p.img}) no-repeat;
	background-size: 100% 70%;

	&::after {
		content: '';
		position: absolute;
		height: 100vh;
		width: 100%;
		background: linear-gradient(0deg, #0A0618 52.82%, rgba(10, 6, 24, 0) 105.22%);
		z-index: -1;
	}

	pointer-events:  ${p => p.open? 'all' : 'none' };

	${p => p.open ? 
		css`
			transform: translateX(0);
		` : 
		css`
			transform: translateX(100%);
			`
}
		
`;

const Content = styled.div`
	overflow: auto;
	height: 100%;
`;



const Pages = styled.div`
    display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	gap: 2px;
`;

const Buttons = styled.div`
	width: 100%;
	margin: 48px 0px;
	display: flex;
	justify-content: space-around;
	align-items: center;

	& > button {
		width: 156px;

	}
`;

 
export default SlidingPanel;
