import styled from 'styled-components';
import content from '../../data/content';
import { theme } from '../../style/theme';
import { BasicLink } from '../../components/buttons/BasicLink';
import { useTranslation } from 'react-i18next';
import { isObjectEmpty } from '../../utilities/helpers';
import BasicButton from '../../components/buttons/BasicButton';
import IconButton from '../../components/buttons/IconButton';
import banner from '../../assets/images/usfbanner.png';
import logo from '../../assets/images/footerlogo.png';

import { useAtomValue } from 'jotai';
import { mobileAtom } from '../../atoms/global';

const Footer = () => {
	const {t} = useTranslation();
	const mobile = useAtomValue(mobileAtom);
    
	return ( 
		<Container>
			{mobile && <BannerContainer>
				<Banner src={logo} />
			</BannerContainer>}

			<PagesContainer>
				{Object.entries(content.pages).map(([pageId, page]) => {
					if(!isObjectEmpty(page.subPages))
						return (
							<Page key={`page-${pageId}`}>
								<p style={{color: theme.colors.secondary, marginTop: 0}}>{page.title}</p>
								{Object.entries(page.subPages).map(([key,detailedPage]) =>{
									return (				
										<BasicLink key={`page-${pageId}-${key}`} to={pageId === 'home' ? '/' : `/${pageId}/${key}`}>
											{t(detailedPage.title)}
										</BasicLink>
									);
								})}
							</Page>
						);
				})}
			</PagesContainer>

			<ButtonsContainer>
				<BasicButton onClick={() => window.location.href = 'mailto:info@usf.sport'}>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3.66663 4.33325L7.99996 8.16659L12.3333 4.33325M4.49996 12.1666H11.5C12.2363 12.1666 12.8333 11.5696 12.8333 10.8333V5.16659C12.8333 4.43021 12.2363 3.83325 11.5 3.83325H4.49996C3.76358 3.83325 3.16663 4.43021 3.16663 5.16659V10.8333C3.16663 11.5696 3.76358 12.1666 4.49996 12.1666Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
                    E-mail info@usf.sport
				</BasicButton>
				<BasicButton onClick={() => window.location.href = 'tel:+31107410073'}>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.92853 3.16675H4.04541C3.56007 3.16675 3.16663 3.5602 3.16663 4.04554C3.16663 8.89895 7.10109 12.8334 11.9545 12.8334C12.4398 12.8334 12.8333 12.44 12.8333 11.9546V10.0715L10.7619 8.69056L9.68777 9.76465C9.50122 9.9512 9.22243 10.0092 8.98803 9.88813C8.59049 9.68277 7.94539 9.29689 7.30948 8.69056C6.65841 8.06977 6.27695 7.40084 6.08548 6.99605C5.97819 6.7692 6.0403 6.50735 6.21775 6.32991L7.30948 5.23818L5.92853 3.16675Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
                    Call +31 10 741 00 73
				</BasicButton>
				<BasicButton onClick={() => window.open('https://www.google.com/maps/place//data=!4m2!3m1!1s0x47c642d93e4cb9f9:0xf33bf44b6e0c637d?sa=X&ved=1t:8290&ictx=111','_blank')}>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.33329 6.00008C8.33329 6.18415 8.18403 6.33342 7.99996 6.33342C7.81589 6.33342 7.66663 6.18415 7.66663 6.00008C7.66663 5.81599 7.81589 5.66675 7.99996 5.66675C8.18403 5.66675 8.33329 5.81599 8.33329 6.00008Z" stroke="#D1D1DB" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M3.8333 9.16675L3.30953 12.0475C3.23512 12.4567 3.54951 12.8334 3.96544 12.8334H12.0345C12.4504 12.8334 12.7648 12.4567 12.6904 12.0475L12.1666 9.16675M10.8333 5.96675C10.8333 8.00008 7.99997 10.1667 7.99997 10.1667C7.99997 10.1667 5.16663 8.00008 5.16663 5.96675C5.16663 4.42035 6.43516 3.16675 7.99997 3.16675C9.56477 3.16675 10.8333 4.42035 10.8333 5.96675Z" stroke="#D1D1DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
                    KNVB Campus, Zeist, Netherlands
				</BasicButton>
			</ButtonsContainer>

			<SocialMediaRow>
				<IconButton>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z" stroke="#1877F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M12.75 19V11.75C12.75 10.6454 13.6454 9.75 14.75 9.75H16.25" stroke="#1877F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M10.75 13.75H15.25" stroke="#1877F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</IconButton>
				<IconButton><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.31 18.25C14.7819 18.25 17.7744 13.4403 17.7744 9.26994C17.7744 9.03682 17.9396 8.83015 18.152 8.73398C18.8803 8.40413 19.8249 7.49943 18.8494 5.97828C18.2031 6.32576 17.6719 6.51562 16.9603 6.74448C15.834 5.47393 13.9495 5.41269 12.7514 6.60761C11.9785 7.37819 11.651 8.52686 11.8907 9.62304C9.49851 9.49618 6.69788 7.73566 5.1875 5.76391C4.39814 7.20632 4.80107 9.05121 6.10822 9.97802C5.63461 9.96302 5.1716 9.82741 4.75807 9.58305V9.62304C4.75807 11.1255 5.75654 12.4191 7.1444 12.7166C6.70672 12.8435 6.24724 12.8622 5.80131 12.771C6.19128 14.0565 7.87974 15.4989 9.15272 15.5245C8.09887 16.4026 6.79761 16.8795 5.45806 16.8782C5.22126 16.8776 4.98504 16.8626 4.75 16.8326C6.11076 17.7588 7.69359 18.25 9.31 18.2475V18.25Z" stroke="#1DA1F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				</IconButton>
				<IconButton onClick={() => window.open('https://nl.linkedin.com/company/usfsport')}>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.75 7.75C4.75 6.09315 6.09315 4.75 7.75 4.75H16.25C17.9069 4.75 19.25 6.09315 19.25 7.75V16.25C19.25 17.9069 17.9069 19.25 16.25 19.25H7.75C6.09315 19.25 4.75 17.9069 4.75 16.25V7.75Z" stroke="#0A66C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M10.75 16.25V14C10.75 12.7574 11.7574 11.75 13 11.75C14.2426 11.75 15.25 12.7574 15.25 14V16.25" stroke="#0A66C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M10.75 11.75V16.25" stroke="#0A66C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M7.75 11.75V16.25" stroke="#0A66C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M7.75 8.75V9.25" stroke="#0A66C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</IconButton>
			</SocialMediaRow>

			{mobile && <BannerContainer style={{paddingBottom: 0}}>
				<Banner src={banner} />
				<OverlayText>
					<p>© 2024 USF.Sport</p>
					<a style={{textDecoration: 'underline'}} href='https://www.usportfor.com/privacyverklaring/' target='_blank'>privacy statement</a>
				</OverlayText>
			</BannerContainer>}

		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: row;

		//mobile
		@media (max-width: ${p => p.theme.responsive.media.lg}){
			flex-direction: column;

    }; 

    & > *{
      padding: 5%;  
    }
	margin-top: 10%;
`;

const PagesContainer = styled.div`
	display: flex;
    
	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
	    display: grid;
    
    	grid-template-columns: repeat(2, 1fr);
    	grid-template-rows: repeat(2, 1fr); 
    }; 

    gap: 32px;
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonsContainer = styled.div`
    display: flex;
	flex-direction: column;
    gap: 16px;

`;

const SocialMediaRow = styled.div`
    display: flex;
    justify-content: center;
	align-items: center;
    gap: 5%;
`;


const BannerContainer = styled.div`
	position: relative;
	padding: 5% 0;
`;

const Banner = styled.img`
	background-size: cover;
	width: 100%;
`;

const OverlayText = styled.span`
	display: flex;
	justify-content: space-between;
	align-items: center;

	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 5%;
	font-size: 14px;
`;
 
export default Footer;