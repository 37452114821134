import styled from 'styled-components';
import BaseButton from './BaseButton';

const IconButton = styled(BaseButton)<{$fill?: boolean}>`

    border: 1px solid #1DA1F2;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default IconButton;